// career

.career-wrap{
	.career-banner{
		background-size: cover;
		background-position: center bottom;
		background-repeat: no-repeat;
		position: relative;
		h4{
			position: absolute;
			bottom: 30px;
			right: 30px;
			text-transform: uppercase;
			font-size: 24px;
			color: $wt;
			text-align: right;
		}
	}
	.career-form{
		padding: 70px 0;
		@media screen and (max-width: 767px){
			padding: 30px 0;
		}
		h5{
			font-family: $b;
			text-transform: uppercase;
			margin: 0;
		}
		h2{
			margin: 5px 0 30px 0;
			opacity: 0.3;
		}
		.btn{
			margin-top: 30px;
			@media screen and (max-width: 767px){
				margin-top: 15px;
			}
		}
	}
}