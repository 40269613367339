// header

.admin-bar header{
	top: 32px;
}
header{
	border-bottom: none;
	position: fixed;
	top: 0;
	padding: 5px 0 0 0;
	width: 100%;
	z-index: 1019;
	background-color: rgba(255, 255, 255, 0.5);
	@include transition(all, 0.3s, ease);
	@media screen and (max-width: 767px){
		padding: 0;
	}
	.navbar-header{
		@media screen and (max-width: 767px){
			text-align: center;
			position: relative;
		}
		.navbar-brand{
			padding: 4px 15px 2px 15px;
			height: 70px;
			margin-bottom: -4px;
			@media screen and (max-width: 991px){
				padding: 10px 0px;
			}
			@media screen and (max-width: 767px){
				float: none;
				display: inline-block;
				height: auto;

			}
			img{
				width: 130px;
				height: auto;
				@media screen and (max-width: 991px){
					width: 90px;
				}
			}
		}
		.navbar-toggle{
			position: absolute;
			top: 50%;
			left: 10px;
			margin: 0;
			border: none;
			@include transform(translateY(-50%));
			.icon-bar{
				background-color: $wt;
			}
		}
	}
	.navbar-collapse{
		float: left;
		@media screen and (max-width: 1199px){
			padding: 0px;
		}
		@media screen and (max-width: 767px){
			float: none;
			background-color: $wt;
			border-top: 1px solid $sPurple;
		}
		.navbar-nav{
			margin-left: 20px;
			@media screen and (max-width: 1199px){
				margin-left: 0px;
			}
			@media screen and (max-width: 767px){
				margin: 0;
			}
			li{
				&.dropdown:hover > .dropdown-menu{
					display: block;
				}
				a{
					color: $gray6;
					text-transform: uppercase;
					font-family: $b;
					padding: 25px 8px;
					@include transition(all, 0.3s, ease);
					@media screen and (max-width: 1199px){
						padding: 25px 5px;
						font-size: 14px;
					}
					@media screen and (max-width: 991px){
						font-size: 12px;
					}
					@media screen and (max-width: 767px){
						padding-top: 10px;
						padding-bottom: 10px;
						color: $gray6;
					}
				}
				.dropdown-menu{
					@media screen and (max-width: 767px){
						padding: 0px;
						border-radius: 0px;
						box-shadow: none;
						position: relative;
						width: 100%;
					}
					li{
						a{
							color: $gray6;
							padding-top: 10px;
							padding-bottom: 10px;
							@media screen and (max-width: 767px){
								padding-top: 5px;
								padding-bottom: 5px;
								background-color: $sPurple;
								color: $wt;
							}
							&:hover, &:focus{	
								background-color: transparent;
								border-color: transparent;
								color: $bl;
							}
						}
					}
				}
			}
		}
		.nav > li > a:hover, .nav > li > a:focus{
			background-color: transparent;
			border-color: transparent;
			color: $bl;
		}
		.nav .open > a, .nav .open > a:hover, .nav .open > a:focus{
			background-color: transparent;
			border-color: transparent;
			color: $bl;
		}
		.dropdown-menu > .active > a{
			background-color: transparent;
		}
	}
	.opt-head{
		float: right;
		@media screen and (max-width: 767px){
			float: none;
			margin: 0px;
			position: absolute;
			right: 20px;
			top: 12px;
		}
		li{
			@media screen and (max-width: 767px){
				float: left;
			}
			a{
				color: $gray6;
				font-size: 16px;
				padding: 25px 7px;
				@include transition(all, 0.3s, ease);
				@media screen and (max-width: 991px){
					font-size: 12px;
					padding: 25px 3px;
				}
				@media screen and (max-width: 767px){
					padding: 10px 3px;
					font-size: 14px;
				}
				&:hover, &:focus{
					background-color: transparent;
				}
			}
		}
		li.search{
			a{
    			display: inline-block;
    			padding: 24px 7px;
    			height: 70px;
    			cursor: pointer;
				@media screen and (max-width: 767px){
					padding: 10px 3px;
					height: auto;
				}
			}
			form{
				margin: 0px;
				display: inline-block;
			}
			input{
				width: 0px;
				opacity: 0;
				margin-left: 0px;
				font-family: $r;
				border: 0px solid transparent;
				@include transition(all, 0.3s, ease);
				@media screen and (max-width: 767px){			    
					@include transition(all, 0s, ease);
				}
			}
			&:before, &:after{
				content: "";
				width: 1px;
				height: 20px;
				position: absolute;
				top: 50%;
				background-color: rgba(34, 34, 34, 0.1);
				@include transform(translateY(-50%));
				@media screen and (max-width: 767px){
					display: none;
				}
			}
			&:before{
				left: 0;
			}
			&:after{
				right: 0;
			}
			&.opened{
				input{
					width: 150px;
					opacity: 1;
					margin-left: 10px;
					border: 1px solid rgba(34, 34, 34, 0.1);
					@include border-radius(5px);
					@media screen and (max-width: 1199px){
					    width: 80px;
					    margin-left: 5px;
					}
					@media screen and (max-width: 991px){
					    position: absolute;
					    width: 150px;
					    top: 45px;
						right: 0;
					}
					@media screen and (max-width: 767px){
					    top: 30px;
					}
				}
			}
		}
		/*li:first-child{
			position: relative;
			@media screen and (max-width: 767px){
				display: none;
			}
			&:after{
				content: "";
				width: 1px;
				height: 20px;
				position: absolute;
				right: 0;
				top: 50%;
				background-color: rgba(34, 34, 34, 0.1);
				@include transform(translateY(-50%));
			}
		}*/
	}
	&.scrolled{
		background-color: $wt;
		@include box-shadow(0, 0, 10px, 2px,rgba(0,0,0,0.1));
		.navbar-toggle{
			.icon-bar{
				background-color: $gray6;
			}
		}
		ul.navbar-nav{
			li{
				a{
					color: $gray6;
				}
			}
		}
	}
}