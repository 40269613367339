// mixins

.title-deck{
	margin-bottom: 60px;
	text-align: center;
	@media screen and (max-width: 767px){
		margin-bottom: 30px;
	}
	h2{
		margin: 0px 0 10px 0;
		font-size: 30px;
		@media screen and (max-width: 767px){
			font-size: 24px;
		}
	}
	p{
		font-size: 16px;
		opacity: 0.7;
		line-height: 1.5;
		margin: 0px;
		@media screen and (max-width: 767px){
			font-size: 14px;
		}
	}
}

.kc-row-container .kc-wrap-columns, .kc_row_inner{
	display: block !important;
}

/*.kc_col-sm-1, .kc_col-sm-2, .kc_col-sm-3, .kc_col-sm-4, .kc_col-sm-5, .kc_col-sm-6, .kc_col-sm-7, .kc_col-sm-8, .kc_col-sm-9, .kc_col-sm-10, .kc_col-sm-11, .kc_col-sm-12{ flex-basis: 0; flex-grow: 1; }*/

/*@media screen and (max-width: 767px){
	.kc_col-sm-1, .kc_col-sm-2, .kc_col-sm-3, .kc_col-sm-4, 
	.kc_col-sm-5, .kc_col-sm-6, .kc_col-sm-7, .kc_col-sm-8, 
	.kc_col-sm-9, .kc_col-sm-10, .kc_col-sm-11, .kc_col-sm-12{ width: 100% !important; }
}
*/
/*.kc_col-sm-1{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 8.33333%;
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.kc_col-sm-2{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 16.66667%;
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.kc_col-sm-3{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.kc_col-sm-4{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 33.33333%;
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.kc_col-sm-5{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 41.66667%;
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.kc_col-sm-6{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.kc_col-sm-7{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 58.33333%;
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.kc_col-sm-8{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 66.66667%;
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.kc_col-sm-9{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}

.kc_col-sm-10{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 83.33333%;
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.kc_col-sm-11{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 91.66667%;
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.kc_col-sm-12{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}*/

/* Safari 2-3 */
/*html[xmlns*=""] .kc_col-sm-4 { width: 33%; }*/

/* Safari 2 - 3.1 */
/*html[xmlns*=""]:root .kc_col-sm-4  { width: 33%;  }*/
 
/* Safari 2 - 3.1, Opera 9.25 */
/**|html[xmlns*=""] .kc_col-sm-4 { width: 33%;  }*/

/*@media screen and (-webkit-min-device-pixel-ratio:0) { 
  .kc_col-sm-4 { width: 33%; } 
} */

.hide-sm{
	@media screen and (max-width: 991px){
		display: none;
	}
}

// background color gray
.gray{
	background-color: $gray;
}

.no-padding{
	padding: 0;
}