// footer

footer{
	padding: 60px 0 0px 0;
	background-color: $gray2;
	border: 1px solid $wt;
	@media screen and (max-width: 767px){
		text-align: center;
		padding: 30px 0 0px 0;
	}
	.logo{
		margin-bottom: 10px;
		@media screen and (max-width: 767px){
			display: block;
			margin: 0 auto 10px auto;
		}
	}
	ul{
		margin: 0;
		padding: 0;
		@media screen and (max-width: 767px){
			width: auto;
			margin-bottom: 20px;
			display: inline-block;
		}
		li{
			list-style: none;
			font-size: 14px;
			margin-bottom: 5px;
			a{
				color: $text;
				font-size: 14px;
				&:hover, &:focus{
					text-decoration: none;	
					color: $orange;
				}
			}
		}
	}
	ul.socmed{
		li{
			display: inline-block;
			vertical-align: middle;
			margin-right: 15px;
			a{
				font-size: 18px;
			}
		}
	}
	ul.policy{
		float: left;
    	margin-top: 5px;
		@media screen and (max-width: 767px){
			float: none;
		}
		li{
			display: inline-block;
			vertical-align: middle;
			padding: 0 15px;
		}
		li:first-child{
			padding-left: 0px;
			border-right: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
	ul.association{
		float: right;
		@media screen and (max-width: 767px){
			float: none;
		}
		li{
			display: inline-block;
			vertical-align: middle;
			padding: 0 5px;
			img{
				height: 27px;
			}
		}
		li:first-child{
			padding-right: 20px;
			@media screen and (max-width: 767px){
				padding-right: 0px;
			}
		}
	}
	h5{
		font-size: 16px;
		font-family: $b;
		margin: 0 0 10px 0; 
	}
	hr{
		border-color: rgba(34, 34, 34, 0.05);
		margin-bottom: 20px;
		@media screen and (max-width: 767px){
			margin-bottom: 15px;
			margin-top: 0px;
		}
	}
	p.copyright{
		font-size: 14px;
		margin: 20px 0 0 0px;
		padding: 20px 0;
		background: $gray4;
		text-align: center;
	}
}