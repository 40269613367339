// carousel home
.carousel.home{
	.item{
		.item-wrap{
			position: relative;
			height: 100vh;
			@media screen and (max-width: 767px){
				height: 550px;
			}
			.img-wrap{
				height: 100vh;
				width: 100%;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				@media screen and (max-width: 767px){
					height: 550px;
				}
			}
			.container{
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform(translate(-50%, -50%));
				@media screen and (max-width: 767px){
					left: 10px;
					@include transform(translate(0%, -50%));
				}
				h2{
					font-family: $b;
					font-size: 48px;
					margin: 0px;
					opacity: 0.85;
					color: $wt;
					@media screen and (max-width: 767px){
						font-size: 40px;
					}
				}
				p{
					color: $wt;
					font-size: 18px;
					width: 70%;
					line-height: 1.4;
					opacity: 0.85;
					margin: 15px 0 25px 0;
					@media screen and (max-width: 767px){
						width: 100%;
						font-size: 16px;
					}
				}
				.btn{
					opacity: 0.85;
				}
			}
		}
	}
	.carousel-control{
		background-image: none;
		width: auto;
		bottom: auto;
		text-shadow: none;
		top: 50%;
		@include transform(translateY(-50%));
		@include transition(all, 0.3s, ease);
		@media screen and (max-width: 767px){
			display: none;
		}
		&.left{
			left: 5%;
		}
		&.right{
			right: 5%;
		}
	}
}

.demoplay{
	padding: 70px 0;
	text-align: center;
	background-size: cover !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
	@media screen and (max-width: 767px){
		height: 550px;
		background-image: url('../images/bg-demo-r.jpg') !important;
		background-position: center bottom;
		background-size: 100%;
		padding: 40px 0;
	}
	h2{
		text-align: center;
		font-family: $b;
		font-size: 48px;
		margin: 0;
		@media screen and (max-width: 767px){
			font-size: 36px;
		}
	}
	p{
		font-size: 18px;
		line-height: 1.4;
		opacity: 0.7;
		margin: 20px 0 40px 0;
		@media screen and (max-width: 767px){
			font-size: 15px;
			margin: 10px 0 20px 0;
		}
	}
}

body{
	.services{
		padding: 90px 0;
		background-color: $gray;
		@media screen and (max-width: 767px){
			padding: 45px 0 15px 0;
		}
		.ser-item{
			background-color: $wt;
			display: block;
			min-height: 170px;
			width: 100%;
			margin-bottom: 60px;
			display: flex;
			align-items: center;
			color: $text;
			position: relative;
			@include box-shadow(3px, 4px, 20px, 3px, rgba(0, 0, 0, 0.07));
			@media screen and (max-width: 767px){
				margin-bottom: 20px;
			}
			.ser-image{
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				background-repeat: no-repeat;
				background-position: top right;
				width: 150px;
				@include transition(all, 0.3s, ease);
				@media screen and (max-width: 767px){
					width: 110px;
				}
			}
			.ser-image.normal{
				opacity: 1;
			}
			.ser-image.hovered{
				opacity: 0;
			}
			.ser-text{
				padding: 0 0 0 170px;
				@include transition(all, 0.3s, ease);
				@media screen and (max-width: 767px){
					padding: 0 0 0 130px;
				}
				h3{
					font-size: 20px;
					font-family: $b;
					color: $text2;
					margin: 0 0 5px 0;
					@media screen and (max-width: 767px){
						font-size: 16px;
					}
				}
				p{
					font-size: 16px;
					opacity: 0.7;
					line-height: 1.4;
					margin: 0px;
					@media screen and (max-width: 767px){
						font-size: 14px;
					}
				}
				span{
					color: $orange;
					font-size: 16px;
					font-family: $b;
					margin-top: 5px;
					display: none;
					img{
						display: inline-block;
						vertical-align: top;
						margin-top: 4px;
					}
				}
			}
			&:hover, &:focus{
				text-decoration: none;
				.ser-image.normal{
					opacity: 0;
				}
				.ser-image.hovered{
					opacity: 1;
				}
				.ser-text span{
					display: inline-block;
				}
			}
		}
		.produ-link{
			color: $orange;
			font-size: 18px;
			font-family: $b;
			display: inline-block;
			margin-bottom: 20px;
			@media screen and (max-width: 767px){
				font-size: 16px;
				margin-bottom: 0px;
			}
			&:hover, &:focus{
				text-decoration: none;
			}
		}
	}

	.solutions{
		padding: 90px 0;
		@media screen and (max-width: 767px){
			padding: 45px 0;
		}
		.solu-item{
			position: relative;
			margin-bottom: 50px;
			min-height: 100px;
			display: block;
			color: $text;
			@media screen and (max-width: 767px){
				margin-bottom: 30px;
			}
			.icon{
				position: absolute;
				left: 0;
				top: 0;
				width: 100px;
				height: 100px;
				display: flex;
				align-items: center;
				justify-content: center;
				@include border-radius(50%);
				@include box-shadow(0, 5px, 10px, 5px, rgba(102, 102, 102,.25));
				/*&.first{
					@include box-shadow(0, 5px, 10px, 5px, rgba(122, 104, 230, 0.25));
				}
				&.second{
					@include box-shadow(0, 5px, 10px, 5px, rgba(86, 197, 21, 0.25));
				}
				&.third{
					@include box-shadow(0, 5px, 10px, 5px, rgba(229, 128, 38, 0.25));
				}
				&.fourth{
					@include box-shadow(0, 5px, 10px, 5px, rgba(250, 93, 93, 0.25));
				}
				&.fifth{
					@include box-shadow(0, 5px, 10px, 5px, rgba(191, 185, 22, 0.25));
				}
				&.sixth{
					@include box-shadow(0, 5px, 10px, 5px, rgba(9, 72, 187, 0.25));
				}
				&.seven{
					@include box-shadow(0, 5px, 10px, 5px, rgba(252, 110, 70, 0.25));
				}
				&.eight{
					@include box-shadow(0, 5px, 10px, 5px, rgba(70, 126, 39, 0.25));
				}
				&.nine{
					@include box-shadow(0, 5px, 10px, 5px, rgba(214, 126, 55, 0.25));
				}
				&.ten{
					@include box-shadow(0, 5px, 10px, 5px, rgba(96, 209, 251, 0.25));
				}*/
			}
			.solu-text{
				padding-left: 130px;
				h4{
					font-size: 20px;
					margin: 5px 0 5px 0;
					font-family: $b;
					float: left;
					width: 100%;
					@media screen and (max-width: 991px){
						margin-top: 0;
					}
				}
				p{
					font-size: 16px;
					opacity: 0.7;
					margin: 0;
					line-height: 1.5;
					@media screen and (max-width: 991px){
						font-size: 14px;
					}
				}
			}
			&:hover, &:focus{
				text-decoration: none;
			}
		}
		.solu-link{
			color: $orange;
			font-size: 18px;
			font-family: $b;
			@media screen and (max-width: 767px){
				font-size: 16px;
			}
			&:hover, &:focus{
				text-decoration: none;
			}
		}
	}

	.news{
		padding: 90px 0;
		background-color: $gray;
		@media screen and (max-width: 767px){
			padding: 45px 0;
		}
		.news-item{
			margin-bottom: 50px;
			@include box-shadow(3px, 4px, 20px, 3px, rgba(0, 0, 0, 0.07));
			@include border-radius(5px);
			@media screen and (max-width: 767px){
				margin-bottom: 30px;
			}
			.news-image{
				width: 100%;
				height: 170px;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				@include border-top-left-radius(5px);
				@include border-top-right-radius(5px);
			}
			.news-text{
				background-color: $wt;
				padding: 20px;
				min-height: 150px;
				@include border-bottom-left-radius(5px);
				@include border-bottom-right-radius(5px);
				@media screen and (max-width: 767px){
					min-height: auto;
				}
				a{
					font-size: 18px;
					margin: 0 0 5px 0;
					color: $text;
					display: block;
					line-height: 1.3;
					@include transition(all, 0.3s, ease);
					@media screen and (max-width: 767px){
						font-size: 16px;
					}
					&:hover, &:focus{
						color: $orange;
						text-decoration: none;
					}
				}
				p{
					font-size: 14px;
					margin: 0;
					opacity: 0.7;
					line-height: 1.5;
				}
			}
		}
	}
}