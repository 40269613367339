// buttons

.btn{
	text-align: left;
}

.btn-line{
	font-size: 18px;
	font-family: $b;
	color: $wt;
	border: 1px solid $wt;
	padding: 15px 50px 15px 20px;
	position: relative;
	background-color: transparent;
	@include border-radius(5px);
	img{
		position: absolute;
		right: 20px;
		top: 50%;
		@include transition(all, 0.3s, ease);
		@include transform(translateY(-50%));
	}
	&:hover, &:focus{
		color: $wt;
		box-shadow: none;
		outline: none;
		img{
			right: 30px;
		}
	}
}

.btn-line.orange{
	color: $orange;
	border-color: $orange;
	&:hover, &:focus{
		color: $orange;
	}
}

.btn-primary{
	color: $wt;
    text-align: center;
    font-size: 15px;
    background-color: $orange;
    min-width: 170px;
    font-family: $b;
    border: none;
    padding: 12px 30px;
    @include border-radius(5px);
    @include transition(all, 0.3s, ease);
    &:hover, &:focus{
    	background-color: lighten($orange, 10%);
    }
}