// interactive map
.all-wrap{
	margin-bottom: 100px;
	#container-wrap{
		margin: 160px auto 0px auto !important;
		background: none !important;
	    width: 962px !important;
	    height: 564px !important;
	    overflow: hidden;
		@include box-shadow(4px, 6px, 15px, 3px, rgba(0, 0, 0, 0.1));
		/*#container{
			#open{
				background-color: $gray3;
				h2{
					background: none;
					font-size: 32px;
					color: $orange2;
					font-family: $b;
					text-indent: 0px;
					position: absolute;
					opacity: 1 !important;
					top: -91px;
					margin: 50px 20px 20px 50px;
				}
				div.imap-content{
					position: absolute;
					margin: 0 260px 0 50px;
					opacity: 0.6;
					top: -120px;
					left: 0;
					background: none;
				}
				div{
					background: url('../images/sprite-landing.png') no-repeat 0 0;
				}
				#open-trees{
				    left: -22px;
				    top: 375px;
				    width: 125px;
				    height: 112px;
				    z-index: 1;
				}
				#open-trees2{
				    left: auto;
				    right: 0;
				    top: 175px;
				    width: 125px;
				    height: 112px;
				    z-index: 1;
				}
				#open-trees3{
				    left: auto;
				    right: 289px;
				    top: 393px;
				    width: 125px;
				    height: 112px;
				    z-index: 1;
				}
				#open-mining{
	    			background-position: -423px -32px;
				    width: 245px;
				    height: 168px;
				    top: 550px;
				    left: 0;
				}
				#open-tourism{
				    background-position: -102px -37px;
				    width: 285px;
				    height: 184px;
				    left: 108px;
				    top: 580px;
				}
				#open-plantation{
				    background-position: -28px -245px;
				    width: 233px;
				    height: 145px;
				    left: 232px;
				    top: 575px;
				}
				#open-poultry{
				    background-position: -346px -219px;
				    width: 264px;
				    height: 197px;
				    left: -48px;
				    top: 575px;
				}
				#open-bank{
				    background-position: -380px -528px;
				    width: 304px;
				    height: 335px;
				    right: -102px;
				    left: auto;
				    top: 580px;
				}
				#open-sp{
				    background-position: -17px -393px;
				    width: 358px;
				    height: 291px;
				    right: 55px;
				    left: auto;
				    top: 580px;
				}
				#open-house{
				    background-position: -532px -424px;
				    width: 140px;
				    height: 95px;
				    right: 125px;
				    left: auto;
				    bottom: -95px;
				}
				&:before{
					content: "";
					position: absolute;
				    left: -71px;
				    top: 178px;
				    width: 1500px;
				    height: 600px;
					background-color: $wt;
					border: 5px solid $gray4;
					@include border-radius(75%);
				}
				#get-started{
					width: 520px;
					height: 60px;
					text-indent: 0px;
					background: $orange2;
					right: auto;
					left: 50%;
					top: auto;
					display: block;
					bottom: -60px;
					font-size: 28px;
					color: $wt;
					line-height: 60px;
					text-transform: uppercase;
					text-align: center;
					letter-spacing: 2px;
					font-family: $b;
					@include transform(translateX(-50%));
					@include border-top-right-radius(15px);
					@include border-top-left-radius(15px);
					&:hover, &:focus{
						text-decoration: none;
					}
					&:before{
						content: "";
						position: absolute;
						left: -14px;
						bottom: 0;
						border-left: 15px solid transparent;
						border-right: 0px solid transparent;
						border-bottom: 52px solid $orange2;
					}
					&:after{
						content: "";
						position: absolute;
						right: -14px;
						bottom: 0;
						border-left: 0px solid transparent;
						border-right: 15px solid transparent;
						border-bottom: 52px solid $orange2;
					}
				}
			}
			#town{
				#map-ani{
					@-webkit-keyframes car1-ani {
						0%   { top: -12px;left: 103px;}
						60% { top: 429px;left: 962px; }
						100% { top: 429px;left: 962px; }
					}
					@-moz-keyframes car1-ani {
						0%   { top: -12px;left: 103px;}
						60% { top: 429px;left: 962px; }
						100% { top: 429px;left: 962px; }
					}
					@-ms-keyframes car1-ani {
						0%   { top: -12px;left: 103px;}
						60% { top: 429px;left: 962px; }
						100% { top: 429px;left: 962px; }
					}
					@-o-keyframes car1-ani {
						0%   { top: -12px;left: 103px;}
						60% { top: 429px;left: 962px; }
						100% { top: 429px;left: 962px; }
					}


					@-webkit-keyframes car3-ani {
						0%   { top: 297px;left: -14px;}
						60% { top: 565px;left: 525px; }
						100% { top: 565px;left: 525px; }
					}
					@-moz-keyframes car3-ani {
						0%   { top: 297px;left: -14px;}
						60% { top: 565px;left: 525px; }
						100% { top: 565px;left: 525px; }
					}
					@-ms-keyframes car3-ani {
						0%   { top: 297px;left: -14px;}
						60% { top: 565px;left: 525px; }
						100% { top: 565px;left: 525px; }
					}
					@-o-keyframes car3-ani {
						0%   { top: 297px;left: -14px;}
						60% { top: 565px;left: 525px; }
						100% { top: 565px;left: 525px; }
					}

					@-webkit-keyframes boat1-ani {
						0%   { top: 440px;left: -60px;}
						60% { top: 575px;left: 150px; }
						100% { top: 575px;left: 150px; }
					}
					@-moz-keyframes boat1-ani {
						0%   { top: 440px;left: -60px;}
						60% { top: 575px;left: 150px; }
						100% { top: 575px;left: 150px; }
					}
					@-ms-keyframes boat1-ani {
						0%   { top: 440px;left: -60px;}
						60% { top: 575px;left: 150px; }
						100% { top: 575px;left: 150px; }
					}
					@-o-keyframes boat1-ani {
						0%   { top: 440px;left: -60px;}
						60% { top: 575px;left: 150px; }
						100% { top: 575px;left: 150px; }
					}

					@-webkit-keyframes boat2-ani {
						0%   { top: 400px;left: -27px;}
						60% { top: 575px;left: 320px; }
						100% { top: 575px;left: 320px; }
					}
				}
			}
		}*/

		#manu-ani2{
			width: 40px;
			height: 60px;
			background-size: 100%;
			background-position: 0;
			background-image: url(../images/drones.gif);
			top: -45px;
			left: 638px;
		}

		.info{
			height: 515px;
			.info-wrap{
				overflow-y: auto;
				.close{
					background-image: url(../images/imap/close.png);
				}
			}
		}

		.dtl{
			#zoom-to{
				display: block !important;
				left: auto;
				right: 20px;
				top: 10px;
				padding: 5px 0;
				background-color: $wt;
			}
		}

		#mining-dtl{
			#zoom-to{
				width: 105px;
				height: 25px;    
				background-position: 10px 5px;
			}
		}

		#manufacturing-dtl{
			#zoom-to{
				width: 105px;
				height: 25px;    
				background-position: 10px -85px;
			}
		}

		#security-dtl{
			#zoom-to{
				width: 170px;
				height: 25px;    
				background-position: 8px -206px;
			}
		}

		#finance-dtl{
			#zoom-to{
				width: 130px;
				height: 25px;    
				background-position: 7px -235px;
			}
		}

		#retail-dtl{
			#zoom-to{
				width: 100px;
				height: 25px;    
				background-position: 8px -175px;
			}
		}

		#utilities-dtl{
			#zoom-to{
				width: 120px;
				height: 25px;    
				background-position: 6px -145px;
			}
		}

		#health-dtl{
			#zoom-to{
				width: 150px;
				height: 25px;    
				background-position: 5px -115px;
			}
		}

		#resources-dtl{
			#zoom-to{
				width: 110px;
				height: 25px;    
				background-position: 6px -25px;
			}
		}

		#consumer-dtl{
			#zoom-to{
				width: 90px;
				height: 25px;    
				background-position: 11px -56px;
			}
		}

		#transport-dtl{
			#zoom-to{
				width: 120px;
				height: 25px;    
				background-position: 5px -266px;
			}
		}

		#finance-dtl{
			#zoom-to{
				width: 130px;
				height: 25px;    
				background-position: 7px -235px;
			}
		}

		#connected-dtl{
			#zoom-to{
				width: 315px;
				height: 25px;    
				background-position: 10px -295px;
			}
		}
	}
	#tour-nav ul{
		background: url(../images/tournav-bg.gif) no-repeat -6px 3px;
	}
}