// typhography

@font-face {
  font-family: 'Karla-Regular';
  src: url('../fonts/Karla-Regular.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Karla-Regular.woff') format('woff'), 
       url('../fonts/Karla-Regular.ttf')  format('truetype'), 
       url('../fonts/Karla-Regular.svg#Karla-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karla-Bold';
  src: url('../fonts/Karla-Bold.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Karla-Bold.woff') format('woff'), 
       url('../fonts/Karla-Bold.ttf')  format('truetype'), 
       url('../fonts/Karla-Bold.svg#Karla-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Karla-Regular", sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
}

