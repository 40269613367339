// Variables

$mainColor: #FA1100;
$secondColor: #fafafa;
$text: #222222;
$text2: #35384A;

// Basic Color
$wt: #FFFFFF;
$bl: #000000;

$gray: #F8F9FB;
$gray2: #F1F4F7;
$gray3: #F0F0F0;
$gray4: #ECECEC;
$gray5: #808080;
$gray6: #555555;
$sPurple: #AFB3C9;
$orange: #F77B00;
$orange2: #EB8600;

$fb-color: #3b5998;
$tw-color: #00aced;
$g-color: #dd4b39;

// font
$r: 'Karla-Regular';
$b: 'Karla-Bold';