// inner
.head-inner{
	padding: 150px 0 100px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	@media screen and (max-width: 767px){
		padding: 50px 0;
	}
	h2{
		font-size: 40px;
		text-align: center;
		color: $wt;
		margin: 0 0 10px 0;
		opacity: 0.85;
		@media screen and (max-width: 767px){
			font-size: 32px;
		}
	}
	h4{
		font-size: 18px;
		text-align: center;
		line-height: 1.5;
		margin: 0px;
		opacity: 0.85;
		color: $wt;
		@media screen and (max-width: 767px){
			font-size: 16px;
		}
	}
}

.content-inner{
	padding: 80px 0;
	@media screen and (max-width: 991px){
		padding: 40px 0;
	}
	.title-inner{
		margin-bottom: 20px;
		h2{
			font-size: 36px;
			font-family: $b;
			margin: 0 0 5px 0;
    		line-height: 1.3;
			@media screen and (max-width: 767px){
				font-size: 28px;
			}
		}
		h5{
			font-size: 18px;
			color: $text2;
			margin: 0px;
    		line-height: 1.3;
			@media screen and (max-width: 767px){
				font-size: 16px;
			}
		}
	}
	h4.text-theme{
		font-size: 20px;
		font-family: $b;
		margin: 0;
		text-transform: uppercase;
		@media screen and (max-width: 991px){
			margin-bottom: 20px;
		}
		@media screen and (max-width: 767px){
			font-size: 18px;
		}
	}
	p{
		font-size: 16px;
		line-height: 1.8;
		opacity: 0.7;
		margin-bottom: 20px;
		@media screen and (max-width: 767px){
			font-size: 14px;
			margin-bottom: 15px;
			line-height: 1.5;
		}
		&.nm{
			margin: 0;
			@media screen and (max-width: 767px){
				margin-bottom: 15px;
			}
		}
	}
	h3{
		font-size: 24px;
		font-family: $b;
		line-height: 1.3;
		margin: 0 0 15px 0;
		@media screen and (max-width: 767px){
			font-size: 20px;
		}
	}
	h6{
		font-size: 16px;
		opacity: 0.8;
		font-family: $b;
	}
	ol, ul{
		margin-bottom: 20px;
		@media screen and (max-width: 767px){
			margin-bottom: 15px;
		}
		li{
			font-size: 16px;
			opacity: 0.7;
			line-height: 1.5;
			@media screen and (max-width: 767px){
				font-size: 14px;
			}
		}
	}
	.abilities{
		.img-wrap{
			min-height: 75px;
			margin-bottom: 15px;
		}
		h3{
			min-height: 65px;
			@media screen and (max-width: 767px){
				min-height: auto;
			}
		}
	}
	.abi-link{
		font-size: 18px;
		font-family: $b;
		display: inline-block;
		color: $orange;
		&:hover, &:focus{
			text-decoration: none;
		}
	}
	.directors{
		position: relative;
		min-height: 80px;
		@media screen and (max-width: 767px){
			margin-bottom: 15px;
		}
		.direct-image{
			position: absolute;
			left: 0;
			top: 0;
			width: 80px;
			height: 80px;
			overflow: hidden;
			@include border-radius(50%);
			img{
				width: 100%;
				height: 80px;
				object-fit: cover;	
			}
		}
		.director-detail{
			padding-left: 100px;
		}
		h4{
			font-size: 16px;
			font-family: $b;
			padding-top: 10px;
			margin: 0 0 5px 0;
			text-transform: none;
		}
		h5{
			font-size: 14px;
			text-transform: uppercase;
			opacity: 0.7;
			margin: 0;
			font-family: $r;
		}
		p{
			margin: 5px 0 20px 0;
			font-size: 14px;
		}
	}
}


.map-wrap{
	width: 100%;
	height: 410px;
	@media screen and (max-width: 767px){
		height: 300px;
	}
	.gmap{
		width: 100%;
		height: 100%;
	}
}