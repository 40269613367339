// contact

/* contact form inner page 2 */
.contact-form-inner-page{
	background-color: $gray;
	padding-bottom: 40px;
	.head-inner{
		h2{
		    font-family: $b;
		    font-size: 44px;
		    margin: 0 0 10px 0;
		    color: $text;
		    text-align: center;
		    @media screen and (max-width: 767px){
		    	font-size: 36px;
		    }
		}
		h3{
		    opacity: 0.5;
		    text-align: center;
		    margin: 0;
		}
	}
	.title-form{
		font-family: $b;
		margin: 0 0 35px 0;
		@media screen and (max-width: 767px){
			font-size: 24px;
			margin: 0 0 20px 0;
		}
	}
}

/* address contact wrap */
.address-contact-wrap{
	background-color: $orange;
	padding: 40px 0;
	@media screen and (max-width: 767px){
		padding: 20px 0;
	}
	h5{
		margin: 0 0 10px 0;
		font-family: $b;
		font-size: 16px;
		color: $wt;
		display: inline-block;
		@media screen and (max-width: 991px){
			display: block;
			margin: 10px 0;
			text-align: left;
		}
		@media screen and (max-width: 767px){
			text-align: center;
		}
		a{
			color: $wt;
		}
	}
	ul{
		padding: 0;
		margin: 0;
		@media screen and (max-width: 767px){
			text-align: center;
			margin: 0 0 20px 0;
		}
		li{
			list-style: none;
			color: $wt;
			font-size: 14px;
			a{
				color: $wt;
			}
			i{
				margin-right: 10px;
				display: inline-block;
				vertical-align: middle;
				&.fa-mobile{
					font-size: 25px;
				}
			}
		}
		&.hmb{
			margin: 0 0 30px 0;
			@media screen and (max-width: 767px){
				margin: 0 0 20px 0;
			}
		}
	}
}

/* map wrap */
.map-wrap{
	height: auto;
	.contact-map{
		height: 350px;
		@media screen and (max-width: 767px){
			height: 250px;
			text-align: center;
		}
	}
	.address{
		padding: 40px;
		height: 350px;
		@media screen and (max-width: 767px){
			padding: 20px;
			height: auto;
			text-align: center;
		}
		h2{
			font-family: $b;
			margin-top: 0px;
			font-size: 36px;
			@media screen and (max-width: 767px){
				font-size: 26px;
			}
		}
		h5{
			font-family: $b;
		}
		p{
			opacity: 0.7;
		}
	}
}