// news
.blog-masonry-wrap{
	padding: 60px 0;
	background-color: $gray;
	.blog-masonry-item{
		margin-bottom: 25px;
		.blog-masonry-image{
			position: relative;
			img{
				width: 100%;	
			}
			.overlay-hyperlink{
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				opacity: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(0, 0, 0, 0.5);
				@include transition(all, 0.3s, ease);
				span{
					width: 70px;
					height: 70px;
					background-color: transparent;
					position: relative;
					@include border-radius(50%);
					&:before{
						content: "\f105";
						color: $wt;
					    font: normal normal normal 50px/1 FontAwesome;
						position: absolute;
						left: 50%;
						top: 50%;
						@include transform(translate(-50%, -50%));
					}
				}
			}
			&:hover, &:focus{
				.overlay-hyperlink{
					opacity: 1;
				}
			}
		}
		.blog-masonry-text{
			position: relative;
			background-color: $wt;
			min-height: 200px;
			padding: 30px 30px 50px 30px;
			@media screen and (max-width: 767px){
				min-height: 150px;
			}
			h5.text-category{
				color: $text2;
				text-transform: uppercase;
				margin: 0 0 10px 0;
				font-size: 10px;
			}
			h4{
				color: $text2;
				font-family: $b;
				line-height: 1.3;
			}
			h5.status-post{
				position: absolute;
				left: 30px;
				right: 30px;
				bottom: 20px;
				font-style: italic;
				font-size: 10px;
				margin: 0;
				padding-top: 15px;
				border-top: 1px solid rgba(51, 51, 51, 0.1);
				strong{
					font-style: normal;
					font-family: $b;
				}
			}
		}
	}
}

.pagi{
	text-align: center;
	.pagination{
		li{
			span, a{
				color: $text2;
			    margin: 0 3px;
			    font-size: 16px;
			    background: none;
			    border: none;
			    font-family: $b;
			    border-radius: 3px;
			}
			&.active{
				span, a{
					color: $wt;
					background-color: $orange;
				}
			}
		}
	}
}

.blog-detail-wrap{
	padding-top: 40px;
	.blog-user{
		padding-bottom: 25px;
		margin-bottom: 45px;
		border-bottom: 1px solid $gray4;
		@media screen and (max-width: 767px){
			margin-bottom: 25px;
			padding-bottom: 5px;
		}
		.blog-user-image{
			float: left;
			margin-right: 20px;
			img{
				width: 65px;
				height: 65px;
				border-radius: 35px;
			}
		}
		.blog-user-name{
			float: left;
			h4{
				font-family: $b;
			    line-height: 1.5;
			    color: #333;
			    margin: 0;
			    position: relative;
			    top: 7px;
			}
			p{
				margin: 0;
				margin-top: 6px;
				font-style: italic;
				font-size: 13px;
				color: $text2;
			}
		}
		.blog-date{
			float: right;
			p{
				margin-top: 20px;
			}
			@media screen and (max-width: 767px){
				float: none;
				clear: both;
				margin-left: 85px;
			}
		}
	}
	.blog-detail-title{
		margin-bottom: 30px;
		h3{
			opacity: 0.5;
		    margin: 0;
			@media screen and (max-width: 767px){
				font-size: 18px;
			}
		}
		h2{
			font-family: $b;
			font-size: 44px;
		    margin: 0 0 10px 0;
			@media screen and (max-width: 767px){
				font-size: 36px;
			}
		}
	}
	.blog-detail{
		.blog-detail-image{
			width: 100%;
			height: 300px;
			background-size: cover;
			margin-bottom: 20px;
			@media screen and (max-width: 767px){
				height: 200px;
			}
		}
		.main-detail{
			font-size: 24px;
			@media screen and (max-width: 767px){
				font-size: 20px;
			}
		}
		p{
			margin-bottom: 20px;
		}
	}
	.share-social{
		border-bottom: 1px solid $gray4;
		border-top: 1px solid $gray4;
		padding: 30px 0 ;
		margin-top: 50px;
		.comment-count{
			float: left;
			position: relative;
			top: 5px;
		}
		.share-icon{
			float: right;
			@media screen and (max-width: 767px){
				label{
					display: none;
				}
			}
			.fa{
				border: 1px solid $gray4;
				width: 30px;
				height: 30px;
				padding: 7px 0;
				border-radius: 15px;
				margin-left: 4px;
				text-align: center;
				cursor: pointer;
				@include transition(all, 0.3s, ease)
			}
			.fa-facebook{
				border-color: $fb-color;
				color: $fb-color;
				&:hover{
					background: $fb-color;
					color: $wt;
				}
			}
			.fa-twitter{
				border-color: $tw-color;
				color: $tw-color;
				&:hover{
					background: $tw-color;
					color: $wt;
				}
			}
			.fa-google-plus{
				border-color: $g-color;
				color: $g-color;
				&:hover{
					background: $g-color;
					color: $wt;
				}
			}
		}
	}
	.blog-title{
		font-size: 18px;
		font-family: $b;
		margin: 40px 0;
	}
	.blog-comment{
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid $gray4;
		&.reply{
			margin-left: 85px;
			@media screen and (max-width: 767px){
				margin-left: 40px;
			}
		}
		.blog-user{
			border: none;
			margin: 0;
			padding-bottom: 5px;
			.blog-user-name{
				h4{
					font-size: 16px;
				}
			}
		}
		.blog-comment-content{
			padding-left: 85px;
			color: $text2;
		}
	}
	.comment-button{
		text-transform: uppercase;
		margin-bottom: 30px;
	}
	.blog-recent-list{
		padding-bottom: 15px;
		border-bottom: 1px solid $gray4;
		margin-bottom: 15px;
		.blog-recent-images{
			float: left;
			width: 55px;
			height: 55px;
			border-radius: 30px;
			overflow: hidden;
			margin-right: 15px;
			img{
				min-width: 55px;
				max-width: 80px;
				min-height: 55px;
			}
		}
		.blog-recent-title{
			h4{
				font-family: $b;
				font-size: 13px;
				margin: 3px 0;
			}
			p{
				font-size: 12px;
				margin-bottom: 0px;
			}
			label{
				font-size: 11px;
				color: $gray;
				font-style: normal;
			}
		}
	}
	.blog-more{
		font-size: 12px;
		color: $text2;
		&:hover{
			text-decoration: none;
			opacity: 0.8;
			color: $text2;
		}
	}
}