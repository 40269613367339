// input

input[type="text"].form-control.v1, input[type="email"].form-control.v1, input[type="password"].form-control.v1, input[type="number"].form-control.v1, textarea.form-control.v1 {
    border: none;
    box-shadow: none;
    background: $gray4;
    padding: 20px 25px;
    height: auto;
    font-size: 14px;
    font-family: $b;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    &:hover, &:focus{
        outline: none;
    }
}

input[type="text"].form-control.v2, input[type="email"].form-control.v2, input[type="password"].form-control.v2, input[type="number"].form-control.v2, textarea.form-control.v2, input[type="file"].form-control.v2 {
    border: none;
    border-bottom: 1px solid darken($gray4, 5%);
    box-shadow: none;
    background: transparent;
    padding: 10px 0;
    height: auto;
    @include border-radius(0);
    &:hover, &:focus{
    	outline: none;
    }
}

textarea{
    resize: none;
}

.form-group{
    margin-bottom: 30px;
    @media screen and (max-width: 767px){
        margin-bottom: 15px;
    }
    label.control-label{
        font-family: $b;
        text-transform: uppercase;
        font-size: 12px;
    }   
}